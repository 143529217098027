import React, { FC, useEffect, useMemo, useState } from "react";
import Modal from "../UI/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setPopupInfo } from "../../store/application/action";
import "./info.scss";
import { COUNTRY } from "../../constants/app";

interface IInfoPopup {}

const InfoPopup: FC<IInfoPopup> = () => {
  const { infoPopup, countryId } = useSelector(
    (state: RootState) => ({
      infoPopup: state.applicationReducer.infoPopup,
      countryId: state.applicationReducer.countryId
    })
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    setOpen(infoPopup.open);
  }, [infoPopup.open]);

  const onClose = () => {
    dispatch(setPopupInfo({ open: false, type: "", data:{} }));
  };

  const onAccept = () => {
    if(infoPopup.type === 'R_rated'){
      dispatch(setPopupInfo({ open: false, type:infoPopup.type , data:{rRatedStatus: true} }));
      return;
    }else if(infoPopup.type === 'Companion'){
      dispatch(setPopupInfo({ open: false, type:infoPopup.type, data: {...infoPopup.data, companion: true}  }));
      return;
    }
  };

  const Content = useMemo(() => {
    switch (infoPopup.type) {
      case "COVID-19":
        setTitle("COVID-19 Social Distancing");
        return (
          <div className="covid_annoc">
            <p>Your health and safety is our highest priority.</p>
            <p>
              Due to the current government health recommendations we have
              decided to reserve this seat as a safety precaution and to
              encourage social distancing in our cinemas.
            </p>
          </div>
        );
      case "Special":
        setTitle("Information");
        return (
          <div className="covid_annoc">
            <p>
              You have selected a wheelchair space. Please note, there is no
              physical cinema seat in this location
            </p>
          </div>
        );
      case "privacyPolicy":
        setTitle("Privacy Policy");
        return null;
      case "ticketSaleTC":
        setTitle("Terms");
        return null;
      case "etixTerms":
        setTitle("e-Tix Terms and conditions");
        return null;
      case "R_rated":
        setTitle("YOU SELECTED A 'R' RATED MOVIE");
        return (
          <div className="r_rate_desc">
            <p>
              Rated-R films: Under 17 requires accompanying parent or 
              adult guardian (age 21 or older).
            </p>
            <div>
              <button className="btn gray_btn" onClick={onClose}>
                Decline
              </button>
              <button className="btn black_btn" onClick={onAccept}>
                Accept
              </button>
            </div>
          </div>
        );
      case "Companion":
        setTitle("Your seat selection includes a wheelchair companion seat");
        return (
          <div className="companion_desc">
            <p>
              This seat is reserved for patrons accompanying someone in a wheelchair.
            </p>
            <div className="btn_group">
              <button className="btn gray_btn" onClick={onClose}>Decline</button>
              <button className="btn black_btn" onClick={onAccept}>Accept</button>
            </div>
          </div>
        );  
    }
  }, [infoPopup.type]);

  return (
    <Modal visible={open} className={`${countryId === COUNTRY.CON?'consolidated':countryId === COUNTRY.AFC?'angelika-film-center':''} announcement-popup`} showCloseBtn={false}>
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered modal-lg ">
          <div className="modal-content">
            <button className="close" type="button" onClick={onClose}>
              <span className="close_icon"></span>
            </button>
            <div className="modal-header ng-star-inserted">
              <div className="modal-title">
                <div className="mobile_header">
                  {title ? (
                    <div className={`title ${infoPopup.type}`}>{title}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="modal-body">{Content}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InfoPopup;
