import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  getAdvanceTicketError,
  getAdvanceTicketSuccess,
  getAdvanceTicketWatcher,
  getComingSoonError,
  getComingSoonSuccess,
  getComingSoonTicketWatcher,
  getComingSoonWatcher,
} from "../../../../store/films/action";
import { IFormatMovie, IMovieData } from "../../../../models/cinema";
import ComingSoon from "../../../../components/Session/US/ComingSoon";
import "./comingSoon.scss";
import { IDropDown } from "../../../../models/common";
import { COUNTRY } from "../../../../constants/app";
import { useLocation, useNavigate } from "react-router";
import { sleep } from "../../../../utils/helper";
import { ROUTES } from "../../../../constants/path";
import { getContentApi } from "../../../../services/films";
import { formatComingSoonAdvanceTicket } from "../../../../utils/formatter";
import CustomLoader from "../../../../components/UI/CustomLoader";

interface IComingSoonProps {}

const ComingSoonSection: FC<IComingSoonProps> = () => {
  const {
    countryId,
    cinemaId,
    comingSoon,
    advanceTicket,
    loader,
    currentCinema,
    loaderComingsoon,
    loaderAdvanceticket,
    quickFilters,
    loaderNowshowing
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    cinemaId: state.applicationReducer.currentCinema?.cinemaId,
    currentCinema: state.applicationReducer.currentCinema,
    comingSoon: state.filmsReducer.comingSoon,
    advanceTicket: state.filmsReducer.advanceTicket,
    loader: state.applicationReducer.loader,
    loaderComingsoon: state.filmsReducer.loaderComingsoon,
    loaderAdvanceticket: state.filmsReducer.loaderAdvanceticket,
    quickFilters: state.filmsReducer.quickFilters,
    loaderNowshowing : state.filmsReducer.loaderNowshowing
  }));

  const scrollRef = useRef<any>(null);
  // const limitLength = window.innerWidth < 600 ? 4 : 8;
  const limitLength = useMemo(() => {
    return window.innerWidth < 1024 ? 5 : window.innerWidth < 1450 ? 8 : 10;
  }, [window?.innerWidth]);

  const TYPE = {
    COMING_SOON: "coming-soon",
    ADVANCE_TICKET: "advance-ticket",
  };
  const [limit, setLimit] = useState<number>(limitLength);
  const [allSession, setAllSession] = useState<IMovieData[]>([]);
  const [toggle, setToggle] = useState(advanceTicket.length > 0?TYPE.ADVANCE_TICKET:TYPE.COMING_SOON);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const scroll = async () => {
    await sleep(500);
    const scroll = scrollRef?.current.offsetTop;
    window.scrollTo({
      top: scroll,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (
      (location.pathname.includes("/coming-soon") ||
        location.pathname.includes("/advance-tickets")) && quickFilters.location
    ) {
      const type =  location.pathname.includes("/coming-soon")
      ? TYPE.COMING_SOON
      : TYPE.ADVANCE_TICKET;
      getApiData(type);
    }
  }, [location]);

  useEffect(() => {
    if (
      (location.pathname.includes("/coming-soon") ||
        location.pathname.includes("/advance-tickets")) &&
        (!loaderNowshowing || !loader)
    ) {
      scroll();
      const type =  location.pathname.includes("/coming-soon")
      ? TYPE.COMING_SOON
      : TYPE.ADVANCE_TICKET;
      setToggle(type);
    }
  }, [loaderNowshowing, loader]);

  useEffect(() => {
    if (comingSoon && toggle === TYPE.COMING_SOON) {
      setAllSession(comingSoon.slice(0, limit));
    }
    if (advanceTicket && toggle === TYPE.ADVANCE_TICKET) {
      setAllSession(advanceTicket.slice(0, limit));
    }
  }, [comingSoon, advanceTicket]);

  const onShowMore = () => {
    if (toggle === TYPE.COMING_SOON && comingSoon.length != allSession.length) {
      setAllSession(comingSoon.slice(0, limit + limitLength));
      setLimit(limit + limitLength);
    } else if (
      toggle === TYPE.ADVANCE_TICKET &&
      advanceTicket.length != allSession.length
    ) {
      setAllSession(advanceTicket.slice(0, limit + limitLength));
      setLimit(limit + limitLength);
    }
  };

  const onShowLess = () => {
    if (toggle === TYPE.COMING_SOON) {
      setAllSession(comingSoon.slice(0, limitLength));
      setLimit(limitLength);
    } else if (toggle === TYPE.ADVANCE_TICKET) {
      setAllSession(advanceTicket.slice(0, limitLength));
      setLimit(limitLength);
    }
    scroll();
  };

  const showMore = useMemo(() => {
    if (toggle === TYPE.COMING_SOON) {
      return (
        comingSoon &&
        comingSoon.length > limitLength &&
        comingSoon.length != allSession.length
      );
    } else {
      return (
        advanceTicket &&
        advanceTicket.length > limitLength &&
        advanceTicket.length != allSession.length
      );
    }
  }, [comingSoon, advanceTicket, allSession]);

  const showLess = useMemo(() => {
    if (toggle === TYPE.COMING_SOON) {
      return (
        comingSoon &&
        comingSoon.length > limitLength &&
        comingSoon.length == allSession.length
      );
    } else {
      return (
        advanceTicket &&
        advanceTicket.length > limitLength &&
        advanceTicket.length == allSession.length
      );
    }
  }, [comingSoon, advanceTicket, allSession]);

  const getApiData = (type: string)=>{
    const flag = type === TYPE.COMING_SOON ? "comingSoon" : "advanceTickets";
    getContentApi({
      countryId: countryId,
      cinemaId: currentCinema?.slug,
      status: "getShows",
      flag: flag,
    }).then((showResponse) => {
      const {
        data: { advanceTicket, comingSoon },
      } = showResponse;
      if (
        comingSoon &&
        comingSoon.statusCode === 200 &&
        type === TYPE.COMING_SOON
      ) {
        dispatch(
          getComingSoonSuccess(
            formatComingSoonAdvanceTicket(
              comingSoon.data.length > 0 ? comingSoon.data : [],
              advanceTicket.data.cmgSessions.length > 0
                ? advanceTicket.data.cmgSessions
                : []
            )
          )
        );
      } else {
        dispatch(getComingSoonError([]));
      }

      if (
        advanceTicket &&
        advanceTicket.statusCode === 200 &&
        type === TYPE.ADVANCE_TICKET
      ) {
        dispatch(getAdvanceTicketSuccess(advanceTicket.data.advSessions));
      } else {
        dispatch(getAdvanceTicketError([]));
      }
    });
  }

  const onChangeTab = (type: string) => {
    setToggle(type);
    navigate(
      `/${currentCinema.alias}/${
        type === TYPE.COMING_SOON ? ROUTES.COMING_SOON : ROUTES.ADVANCE_TICKETS
      }`
    );
  };

  return (
    <section
      className={`coming-soon-section ${
        countryId === COUNTRY.CON
          ? "consolidated"
          : countryId === COUNTRY.AFC
          ? "angelika-film-center"
          : ""
      }`}
      ref={scrollRef}
    >
      <div className="container-content">
        <div className="coming-soon img-section-parent-cont-white">
          <div className="coming-advance">
            <h2
              onClick={() => onChangeTab(TYPE.COMING_SOON)}
              className={`heading ${toggle === "coming-soon" ? "active" : ""}`}
            >
              COMING SOON
            </h2>
            <h2
              onClick={() => onChangeTab(TYPE.ADVANCE_TICKET)}
              className={`heading ${
                toggle === "advance-ticket" ? "active" : ""
              }`}
            >
              ADVANCE TICKETS
            </h2>
          </div>
          <div className="divider">
            <div
              className={`divider_1 ${
                toggle === "coming-soon" ? "active" : ""
              }`}
            ></div>
            <div
              className={`divider_2 ${
                toggle === "advance-ticket" ? "active" : ""
              }`}
            ></div>
          </div>
          {loaderAdvanceticket || loaderComingsoon?<CustomLoader loader={true}/>
          :<div>
          {allSession.length > 0 ? (
            <div className="content-body">
              <div className="img-section-parent-four-list row">
                <div className="card__wrap--outer">
                  {allSession.map((data: any, index: number) => (
                    <ComingSoon key={index} data={data} />
                  ))}
                </div>
              </div>
              {showMore || showLess ? (
                <div className="show-more">
                  {showMore ? (
                    <p onClick={onShowMore}>SHOW MORE</p>
                  ) : (
                    <p onClick={onShowLess}>SHOW LESS</p>
                  )}
                </div>
              ) : null}
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="no-session">No Sessions Available</div>
            </div>
          )}
          </div>}
        </div>
      </div>
    </section>
  );
};

export default ComingSoonSection;
